import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ConfigRequest from '../config/config';
import Login from '../pages/login';
import Home from '../pages/home';
import Checkout from '../pages/checkout';
import Settings from '../pages/settings';
import History from '../pages/history';
import PendingOrders from '../pages/pendingOrders';
const MyRouter = () => {
        const { getToken, token } = ConfigRequest();
        const gtoken = getToken();
    return (

        <Routes>
            {gtoken ? (
                <>
                    <Route path='/' element={<Home />}></Route>
                    <Route path='/home' element={<Home />}></Route>
                    <Route path='/checkout' element={<Checkout />}></Route>
                    <Route path='/setting' element={<Settings />}></Route>
                    <Route path='/history' element={<History />}></Route>
                    <Route path='/pending-orders' element={<PendingOrders />}></Route>

                </>
            ) : (
                <>
                    <Route path='/' element={<Login />}></Route>
                </>
            )}
        </Routes>
    );
};

export default MyRouter;
