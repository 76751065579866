import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import GCSLogo from '../assets/GCS-Logo.png'
import ConfigRequest from '../config/config';
import { useNavigate } from 'react-router-dom';
function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="gcshopay.gcsho.com.pk">
        GCSHO Pay
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function Login() {

  const { setToken, alertMessage, http } = ConfigRequest();
  const navigate = useNavigate();
  const [msg, setMsg] = useState('');
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let formdata = {
      email: data.get('email'),
      password: data.get('password'),

    };

    http.post('/login', formdata).then((res) => {
      setMsg(alertMessage(res.data.status, res.data.msg));
      setTimeout(() => {
        setMsg('')
      }, 2000);
      if (res.data.status == 'success') {
        setToken(res.data.user, res.data.token)
        setTimeout(() => {
          navigate('/');

        }, 1000);
      }



    }).catch((err) => {
      console.log(err)
    })


  };

  return (
    <ThemeProvider theme={defaultTheme}>
      {msg}
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 2, border: 'none', width: '100px', height: '100px' }}>
            <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={GCSLogo} />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>

          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}