import { Backdrop, Button, CircularProgress, TextField } from '@mui/material'
import React, { useState } from 'react'
import GCSLogo from '../assets/logotext.png'
import ConfigRequest from '../config/config'
import { useNavigate } from 'react-router-dom'
import BottomNavigationBar from '../components/bottomNavigation'
import HeaderBar from '../components/Header';
const Home = () => {
    const { alertMessage, http, user } = ConfigRequest();
    const navigate = useNavigate();
    const [msg, setMsg] = useState('');
    const [open, setopen] = useState(false)
    const [orderNo, setOrderNo] = useState('ORD-')


    const handleSubmit = (event) => {
        event.preventDefault();
        setopen(true)
        const data = new FormData(event.currentTarget);
        let formdata = {
            otp: data.get('otp'),
            orderno: data.get('orderno'),
            operator_id: user.id,
            university_id: user.universityid,

        };

        http.post('/requestOTP', formdata).then((res) => {
            setMsg(alertMessage(res.data.status, res.data.msg));
            setTimeout(() => {
                setMsg('')
            }, 5000);
            setopen(false)
            if (res.data.status == 'success') {
                setTimeout(() => {
                    navigate('/checkout', { state: res.data.order });
                }, 1000);
            }



        }).catch((err) => {
            setopen(false)
            setMsg(alertMessage('danger', 'Internal server error'));
        })


    };

    return (
        <>
            {msg}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className='main' style={{ minHeight: '100vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'start', flexDirection: 'column', gap: '20px' }}>

               <HeaderBar/>
                <form className='home-inputgroup' onSubmit={handleSubmit}>
                    <h2>Enter Details</h2>

                    <TextField
                        required
                        id="outlined-required"
                        label="Order OTP"
                        sx={{ width: '100%' }}
                        name='otp'
                    />
                    <TextField
                        required
                        name='orderno'
                        id="outlined-required"
                        value={orderNo}
                        onChange={(e)=>setOrderNo(e.target.value)}
                        label="Order Number"
                        sx={{ width: '100%' }}
                    />
                    <Button variant="contained" type='submit' sx={{ mt: '20px' }} color="success">Search</Button>
                </form>
            </div>
            <BottomNavigationBar />
        </>
    )
}

export default Home