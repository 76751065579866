
import React, { useEffect, useState } from 'react'
import HeaderBar from '../components/Header';
import BottomNavigationBar from '../components/bottomNavigation';
import ConfigRequest from '../config/config';
import { CircularProgress } from '@mui/material';

const PendingOrders = () => {
    const [isLoading, setIsLoading] = useState(true)
    const { http, user } = ConfigRequest();
    const [data, setData] = useState([])
    const fertchOrder = async () => {

        await http.post('/getOrders', { 'status': '0', 'operator_id': user.id, 'university_id': user.universityid }).then((res) => {
            setIsLoading(false);
            if (res.data.status === 'success') {
                setData(res.data.orders);
            }
        }).catch((err) => {
            console.log(err)
        })
    }
    useEffect(() => {
        fertchOrder();
    }, [])


    return (
        <>

            <div className='main' style={{ minHeight: '120vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'start', flexDirection: 'column', gap: '20px' }}>
                <HeaderBar />
                {isLoading ? (<CircularProgress color="success" />) : ''}

                <ul className='orderList'>
                    {data.length > 0 ?
                        data?.map((item, i) => (
                            <li className='orderlistitem' key={i}>
                                <div>
                                    <p>Student Id</p>
                                    <p> <b>{item.student_university_id}</b></p>
                                </div>
                                <div>
                                    <p style={{ textAlign: 'end' }}>Plan</p>
                                    <p style={{ textAlign: 'end' }}> <b>{item.title}</b></p>
                                </div>
                            </li>
                        )) : (

                            <center> <p>Pending order not found</p></center>
                        )}
                </ul>

            </div>
            <BottomNavigationBar />
        </>
    )
}

export default PendingOrders