import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import RestoreIcon from '@mui/icons-material/Restore';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { Paper } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

export default function BottomNavigationBar() {
    const [value, setValue] = React.useState('/');
    const navigate = useNavigate();
    const location = useLocation();
    const [path, setPath] = React.useState('/')
    React.useEffect(() => {
        setPath(location.pathname)
    }, [location]);

    return (
        <Paper sx={{ position: 'fixed', bottom: 10, left: 10, right: 10, borderRadius: '50px', background: 'transparent' }} elevation={3}>
            <BottomNavigation
                sx={{ borderRadius: '50px', background: 'white' }}
                showLabels
                value={value}
                onChange={(event, newValue) => {
                    setValue(newValue);
                    navigate(newValue)
                }}
            >
                <BottomNavigationAction
                    className={path  == '/history' ? 'navactivee' : ''}
                    value={'/history'}
                    label="History"
                    icon={<RestoreIcon />}
                />
                <BottomNavigationAction className={path == '/' ? 'navactivee' : ''} value={'/'} label="Home" icon={<HomeOutlinedIcon />} />
                <BottomNavigationAction className={path == '/setting' ? 'navactivee' : ''} value={'/setting'} label="Settings" icon={<SettingsOutlinedIcon />} />
            </BottomNavigation>
        </Paper>
    );
}
