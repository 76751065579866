import { Backdrop, Button, CircularProgress, Grid, TextField } from '@mui/material';
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import ConfigRequest from '../config/config';
import BottomNavigationBar from '../components/bottomNavigation';
import HeaderBar from '../components/Header';
const Checkout = () => {
    const [msg, setMsg] = useState('');
    const [open, setopen] = useState(false)
    const { alertMessage, http, user } = ConfigRequest();
    const location = useLocation();
    const navigate = useNavigate();
    const detail = location.state;
    const [Confirm, setConfirm] = useState(false)

    const handleSubmit = (event) => {
        event.preventDefault();
        setopen(true)
        const data = new FormData(event.currentTarget);
        let formdata = {
            operator_id: user.id,
            order_id: detail.orderid,
            totalAmount: detail.totalAmount,
            university: detail.university,
            plan_id: detail.plan,
            user_id: detail.user_id,

        };

        http.post('/confirmOrder', formdata).then((res) => {
            setMsg(alertMessage(res.data.status, res.data.msg));
            setTimeout(() => {
                setMsg('')
            }, 5000);
            setopen(false)
            if (res.data.status == 'success') {
                setConfirm(true)
            }


        }).catch((err) => {
            setopen(false)
            console.log(err)
        })


    };


    return (
        <>
            {msg}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className='main' style={{ minHeight: '120vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'start', flexDirection: 'column', gap: '20px' }}>
            <HeaderBar/>
             
                <form className='home-inputgroup' onSubmit={handleSubmit}>
                    <h2>{detail.student_name}</h2>
                    <table id='orderDetail'>
                        <tr>
                            <th>Order number</th>
                            <td>{detail.orderno}</td>
                        </tr>
                        <tr>
                            <th>Student Id</th>
                            <td>{detail.student_university_id}</td>
                        </tr>
                        <tr>
                            <th>Full Name</th>
                            <td>{detail.student_name}</td>
                        </tr>
                        <tr>
                            <th>Plane</th>
                            <td>{detail.planName}</td>
                        </tr>
                        <tr>
                            <th>Plane Amount</th>
                            <td>{detail.planValue}</td>
                        </tr>
                        <tr>
                            <th>Cash Handling Charges</th>
                            <td>10</td>
                        </tr>
                        <tr>
                            <th>Total Amount</th>
                            <td>RS {detail.totalAmount}</td>
                        </tr>

                    </table>
                    {Confirm ? (

                        <Button variant="contained" type='buton' onClick={() => { navigate('/') }} sx={{ mt: '20px' }} color="primary">Back</Button>
                    ) : (
                        <Grid container spacing={2} direction="row"
                            justifyContent="space-between">
                            <Grid item >
                                <Button variant="contained" type='buton' onClick={() => { navigate('/') }} sx={{ mt: '20px' }} color="primary">Back</Button>
                            </Grid>

                            <Grid item >
                                <Button variant="contained" type='submit' sx={{ mt: '20px' }} color="success">Confirm</Button>
                            </Grid>

                        </Grid>

                    )}
                </form>
            </div>
            <BottomNavigationBar />
        </>
    )
}

export default Checkout