import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { Snackbar } from '@mui/material';

export default function ConfigRequest() {

    const navigate = useNavigate();
    const logout = () => {
        localStorage.clear();
        navigate('/');
    };

    const saveToken = (user, token) => {
        localStorage.setItem('token', JSON.stringify(token));
        localStorage.setItem('user', JSON.stringify(user));
        setToken(token);
        setUser(user);
        navigate('/')
    }
    const getToken = () => {
        const tokenString = localStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        return userToken;
    }
    const getUser = () => {
        const UserString = localStorage.getItem('user');
        const user_detail = JSON.parse(UserString);
        return user_detail;
    }

    const [open, setOpen] = useState(true);

  

    const alertMessage = (status, message) => {
        setOpen(true)

        return (
            <Snackbar open={open} autoHideDuration={2000}  >
                <Alert variant="filled" severity={status} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        )
    };


    const [token, setToken] = useState(getToken);
    const [user, setUser] = useState(getUser);

    const http = axios.create({
        baseURL: 'https://www.gcshopay.gcsho.com.pk/operator/api/',
        headers: {
            "Content-Type": "multipart/form-data",

        },


    });


    return {
        alertMessage,
        setToken: saveToken,
        user,
        setUser,
        token,
        getToken,
        // logout,
        // ticket,
        http,
        logout,
    }
}