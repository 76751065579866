import React, { useEffect, useState } from 'react'
import GCSLogo from '../assets/logotext.png'
import { useLocation, useNavigate } from 'react-router-dom';
import ConfigRequest from '../config/config';
import CachedIcon from '@mui/icons-material/Cached';
const HeaderBar = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [totalAmount, settotAmount] = useState('')
    const [pendingOrder, setpendingOrder] = useState('')
    const [openRefresh, setOpenRefresh] = useState(true)

    const { http, user } = ConfigRequest();

    const headerDetails = async () => {
        setOpenRefresh(false)
        try {
            const res = await http.post('/headerDetails', {
                'operator_id': user.id,
                'university_id': user.universityid
            });

            if (totalAmount != res.data?.totalAmount) {

                settotAmount(res.data.totalAmount);
            }
            if (pendingOrder !== res.data.pendingOrder) {

                setpendingOrder(res.data.pendingOrder);
            }

            setOpenRefresh(true)

        } catch (error) {

            setOpenRefresh(true)

            console.error(error);
        }
    };

    useEffect(() => {
        headerDetails();
    }, [location.pathname])

    return (
        <div className='bannernn' >
            <img src={GCSLogo} style={{ width: '200px', marginBottom: '20px' }} />

            <div className='operatorDetails'>
                <div className='totalAmount'>
                    <p className='operatorDetails-head'>Total Amount</p>
                    <p className='operatorDetails-body'> Rs.{totalAmount} <span className={!openRefresh ? 'spinner' : ''} onClick={() => { headerDetails() }}><CachedIcon /></span></p>
                </div>

                <div className='PendingOrder' onClick={() => { navigate('/pending-orders') }}>
                    <p className='operatorDetails-head'>Pending Orders</p>
                    <p className='operatorDetails-body'>{pendingOrder} </p>
                </div>
            </div>
        </div>
    )
}

export default HeaderBar