import { Backdrop, Button, CircularProgress, TextField } from '@mui/material'
import React, { useState } from 'react'
import GCSLogo from '../assets/logotext.png'
import ConfigRequest from '../config/config'
import { useNavigate } from 'react-router-dom'
import BottomNavigationBar from '../components/bottomNavigation'
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
const Settings = () => {
    const { logout, user } = ConfigRequest();
    const navigate = useNavigate();
    const [open, setopen] = useState(false)





    return (
        <>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className='main' style={{ height: '95vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'start', flexDirection: 'column', gap: '20px' }}>
                <div className='settingHeader'>
                    <h2>{user.fullname}</h2>
                    <p>{user.university_name} </p>
                </div>
                <ul className='settinglist'>
                     
                    <li onClick={() => { logout(); }}>
                        <span className='icon'>
                        <LogoutOutlinedIcon/>
                        </span>
                        <span className='text'>
                            Logout
                        </span>
                    </li>

                </ul>

            </div>
            <BottomNavigationBar />
        </>
    )
}

export default Settings